<template>
  <ModuleContainer>
    <div class="layer-container pa-2">
      <ButtonGetLocation @update:location="$emit('update:location', $event)" />
      <v-btn
        dark
        small
        v-for="(layer, id) in layersMap"
        :key="id"
        rounded
        :ripple="false"
        @click="onToggleShowLayer(layer)"
        class="text-capitalize d-inline-block mx-1 mb-2"
        :color="layer.layout.visibility != 'none' ? '#428CD4' : ''"
      >
        <v-icon left small> mdi-map-marker-outline </v-icon>
        {{ layer.name }}
        <template v-if="isMobile"> trong khu vực </template> ({{
          countFeatureLayer[layer.layer_id] ||
          layer.features_count | formatNumber
        }})
      </v-btn>
    </div>
  </ModuleContainer>
</template>

<script>
import { Marker, Popup } from "mapbox-gl";
import { promiseMapboxLayerManagement } from "@/worker";
import ModuleMixin from "../../mixins/ModuleMixin";
import { bbox as bboxTurf, polygon, booleanPointInPolygon } from "@turf/turf";
import { clone } from "lodash";
import { loadingContainer } from "@/service/loading";
import SearchResultPopupVue from "@/views/SearchResultPopup.vue";
import Vue from "vue";
import ButtonGetLocation from "@components/ButtonGetLocation.vue";
import { mapGetters } from "vuex";
const PRE_FIX = ["", "-overlap", "-hightlight", "-search"];
const PRE_FIX_DATA = ["", "-overlap"];
const PRE_FIX_DATA_SEARCH = ["-hightlight", "-search"];
export default {
  props: { isComplexSearch: Boolean },
  components: { ButtonGetLocation },
  mixins: [ModuleMixin],
  data: () => ({
    marker: null,
    popup: null,
    layerId: "layer-layer-control",
    sourceId: "source-layer-control",
    bindedOnMouseEnterLayer: null,
    bindedOnMouseLeaveLayer: null,
    bindedOnClickLayer: null,
    bindedOnClickLayers: null,
    layersMap: {},
    isInitDone: false,
    layerPopup: null,
    layerVuePopup: null,
    countFeatureLayer: {},
  }),
  watch: {
    items: {
      handler(value) {
        if (this.isInitDone && value && value.length > 0) this.onSetData();
      },
    },
    isInitDone: {
      handler(value) {
        if (value) {
          this.onSetData();
        }
      },
    },
  },
  computed: {
    ...mapGetters({
      layers: "map/layers",
      items: "map/items",
    }),
    isMobile() {
      return this.$vuetify.breakpoint.mobile;
    },
    countLayers() {
      return Object.keys(this.layersMap).length;
    },
    currentLayerId() {
      return Object.keys(this.layersMap).reduce((acc, cur) => {
        acc.push(...PRE_FIX.map((x) => `${cur}${x}`));
        return acc;
      }, []);
    },
  },

  methods: {
    onInit() {
      // Use this.map to access mapbox gl map

      this.map.addSource(this.sourceId, {
        type: "geojson",
        data: {
          type: "FeatureCollection",
          features: [],
        },
      });
      this.map.addSource(`${this.sourceId}-search`, {
        type: "geojson",
        data: {
          type: "FeatureCollection",
          features: [],
        },
      });
      this.map.addSource(`${this.sourceId}-hightlight`, {
        type: "geojson",
        data: {
          type: "FeatureCollection",
          features: [],
        },
      });
      this.map.addLayer({
        id: `${this.layerId}-hightlight`,
        type: "circle",
        source: `${this.sourceId}-hightlight`,
        paint: {
          "circle-color": "#2E63E9",
          "circle-stroke-color": "#789EFD",
          "circle-blur": 0.1,
          "circle-pitch-scale": "map",
          "circle-pitch-alignment": "viewport",
          "circle-opacity": 0.3,
          "circle-translate-anchor": "map",
          "circle-radius": 20,
          "circle-stroke-width": 2,
          "circle-stroke-opacity": 1,
        },
      });

      this.layerPopup = new Popup({
        closeButton: false,
        closeOnClick: false,
        maxWidth: "300px",
        className: "popup-place-card-container",
      });
      this.bindedOnMouseEnterLayer = this.onMouseEnterLayer.bind(this);
      this.bindedOnMouseLeaveLayer = this.onMouseLeaveLayer.bind(this);
      this.bindedOnClickLayer = this.onClickLayer.bind(this);
      this.bindedOnClickLayers = this.onClickMapLayers.bind(this);
      this.map.on("click", this.bindedOnClickLayers);
      this.$nextTick(() => {
        this.isInitDone = true;
      });
    },
    onMouseEnterLayer(e) {
      if (this.layerPopup) {
        this.layerPopup.remove();
      }
      this.map.getCanvas().style.cursor = "pointer";
      if (this.isMobile) return;
      let coordinates = e.features[0].geometry.coordinates.slice();
      let data = JSON.parse(e.features[0].properties.data);
      while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
        coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
      }
      this.layerVuePopup = new Vue(SearchResultPopupVue);
      this.layerVuePopup.$mount();
      data.place = this.layers[data.placeTypeId];
      this.layerVuePopup.setData(data);
      this.layerVuePopup.$on("close", () => {
        if (this.layerPopup) {
          this.layerPopup.remove();
        }
        if (this.layerVuePopup) {
          this.layerVuePopup.$destroy();
          this.layerVuePopup = null;
        }
      });
      this.layerVuePopup.$on("click:item", (e) => {
        this.$emit("click:layer", { properties: { data: e } });
      });
      // Populate the popup and set its coordinates
      // based on the feature found.
      this.layerPopup
        .setLngLat(coordinates)
        .setDOMContent(this.layerVuePopup.$el)
        .addTo(this.map);
    },

    onMouseLeaveLayer() {
      this.map.getCanvas().style.cursor = "";
      if (this.layerVuePopup) this.layerVuePopup.check();
    },
    onClickLayer(e) {},
    onClickMapLayers(e) {
      let features = this.map.queryRenderedFeatures(e.point, {
        layers: this.currentLayerId,
      });
      if (features && features.length > 0) this.onClickLayers(features);
    },
    createLayerFromPlace(place) {
      let style = Object.assign(
        { paint: {}, layout: { visibility: "visible" } },
        {
          minzoom: 5,
          id: `${this.layerId}-point-${place.id}`,
          name: place.name,
          filter: ["==", "filter_id", place.id + ""],
          source: this.sourceId,
          features_count: place.features_count,
          layer_id: place.placeTypeId,
        },
        place.style
      );
      return style;
    },
    onShowData() {
      this.redraw(this.items);
    },
    onSetData() {
      if (!this.map || !this.isInitDone) return;
      if (!this.items || this.items.length < 1) return;

      Object.keys(this.layers).forEach((key) => {
        this.createLayerOverlap(this.layers[key]);
      });
      this.redraw(this.items);
    },
    async onSetDataSearch(
      places,
      { fitBounds, padding, bbox } = {},
      { count = {} } = {}
    ) {
      if (!this.map || !this.isInitDone) return;
      this.redraw([]);
      let features = [];
      this.countFeatureLayer = count;
      if (places && places.length > 0) {
        let res = await promiseMapboxLayerManagement.postMessage({
          items: places,
        });
        features = res.features;
      }
      let source = this.map.getSource(`${this.sourceId}-search`);
      source.setData({
        type: "FeatureCollection",
        features,
      });
      if (fitBounds) {
        if (bbox && bbox.length == 4) {
          this.map.fitBounds(bbox, {
            padding: padding || 100,
            duration: 0,
          });
        } else if (features.length > 0) {
          let bboxFil = undefined;
          bboxFil = bboxTurf({
            type: "FeatureCollection",
            features,
          });

          if (bboxFil)
            this.map.fitBounds(bboxFil, {
              padding: padding || 100,
              duration: 0,
            });
        }
      }
    },
    createLayerOverlap(place) {
      let layer = this.createLayerFromPlace(place);
      this.$set(this.layersMap, layer.id, layer);

      let layerHighlight = clone(layer);
      layerHighlight.id = layer.id + "-hightlight";
      layerHighlight.layout = {};
      let layerOverlap = clone(layer);
      layerOverlap.id = layer.id + "-overlap";

      let layerSearch = clone(layer);
      layerSearch.id = layer.id + "-search";

      let layerMapbox = this.map.getLayer(layer.id);
      if (!layerMapbox) {
        layer.minzoom = 11;
        layer.layout["icon-allow-overlap"] = true;
        this.map.addLayer(layer);
        this.map.on("click", layer.id, this.bindedOnClickLayer);
        this.map.on("mouseenter", layer.id, this.bindedOnMouseEnterLayer);
        this.map.on("mouseleave", layer.id, this.bindedOnMouseLeaveLayer);
      }
      layerMapbox = this.map.getLayer(layerOverlap.id);
      if (!layerMapbox) {
        layerOverlap.layout["icon-allow-overlap"] = false;
        layerOverlap.maxzoom = 11;
        this.map.addLayer(layerOverlap);
        this.map.on(
          "mouseenter",
          layerOverlap.id,
          this.bindedOnMouseEnterLayer
        );
        this.map.on(
          "mouseleave",
          layerOverlap.id,
          this.bindedOnMouseLeaveLayer
        );
      }
      layerMapbox = this.map.getLayer(layerSearch.id);
      if (!layerMapbox) {
        layerSearch.layout["icon-ignore-placement"] = true;
        if (layerSearch.layout["text-field"])
          layerSearch.layout["text-allow-overlap"] = true;
        layerSearch.source = `${this.sourceId}-search`;
        delete layerSearch.filter;
        this.map.addLayer(layerSearch);
        this.map.on("mouseenter", layerSearch.id, this.bindedOnMouseEnterLayer);
        this.map.on("mouseleave", layerSearch.id, this.bindedOnMouseLeaveLayer);
      }
      layerMapbox = this.map.getLayer(layerHighlight.id);
      if (!layerMapbox) {
        layer.minzoom = 5;
        layerHighlight.layout["icon-ignore-placement"] = true;
        layerHighlight.source = `${this.sourceId}-hightlight`;
        delete layerHighlight.filter;
        this.map.addLayer(layerHighlight);
      }
    },
    async redraw(places, { fitBounds, padding } = {}) {
      try {
        loadingContainer.addPending("redraw-data", "global");
        const { features } = await promiseMapboxLayerManagement.postMessage({
          items: places,
        });
        let source = this.map.getSource(this.sourceId);
        source.setData({
          type: "FeatureCollection",
          features,
        });
        if (fitBounds) {
          let bboxFil = undefined;
          bboxFil = bboxTurf({
            type: "FeatureCollection",
            features,
          });

          if (bboxFil)
            this.map.fitBounds(bboxFil, {
              padding: padding || 100,
              duration: 0,
            });
        }
        this.redrawHightlight();
      } catch (error) {
        console.error(error);
      } finally {
        loadingContainer.removePending("redraw-data");
      }
    },
    onClickLayers(features) {
      this.$emit("click:layers", features);
      this.$emit("click:layer", features[0]);
    },
    onShowPopup(coordinates, html = "<div></div>") {
      this.onRemovePopup();
      this.popup = new Popup({ closeButton: false })
        .setLngLat(coordinates)
        .setDOMContent(html)
        .addTo(this.map);
    },
    onRemovePopup() {
      if (this.popup) {
        this.popup.remove();
      }
    },
    onMarketItems(arrayCoordinates) {
      const features = arrayCoordinates.map((coordinates) => ({
        type: "Feature",
        geometry: { type: "Point", coordinates },
      }));
      this.redrawHightlight(features);
    },
    onMarket(coordinates) {
      this.redrawHightlight([
        {
          type: "Feature",
          geometry: { type: "Point", coordinates },
        },
      ]);
      // this.onRemoveMarket();
      // this.marker = new Marker({ color: "red" })
      //   .setLngLat(coordinates)
      //   .addTo(this.map);
    },
    onRemoveMarket() {
      // if (this.marker) {
      //   this.marker.remove();
      // }

      this.redrawHightlight([]);
    },
    redrawHightlight(features = []) {
      if (!this.map || !this.isInitDone) return;
      let source = this.map.getSource(`${this.sourceId}-hightlight`);
      if (source)
        source.setData({
          type: "FeatureCollection",
          features,
        });
    },
    flyTo(center, zoom = 12, padding = []) {
      if (!this.map) return;
      this.map.flyTo({ padding, center, zoom, duration: 0 });
    },
    onDestroy() {
      this.currentLayerId.forEach((layerId) => {
        if (this.map.getLayer(layerId)) {
          this.map.removeLayer(layerId);
          this.map.off("click", layerId, this.bindedOnClickLayer);
          this.map.off("mouseenter", layerId, this.bindedOnMouseEnterLayer);
          this.map.off("mouseleave", layerId, this.bindedOnMouseLeaveLayer);
        }
      });
      this.onRemovePopup();
      this.onRemoveMarket();
      this.map.off("click", this.bindedOnClickLayers);
      if (this.map.getSource(this.sourceId)) {
        this.map.removeSource(this.sourceId);
      }
      if (this.map.getLayer(`${this.layerId}-hightlight`)) {
        this.map.removeLayer(`${this.layerId}-hightlight`);
      }
      if (this.map.getSource(`${this.sourceId}-hightlight`)) {
        this.map.removeSource(`${this.sourceId}-hightlight`);
      }
      if (this.map.getSource(`${this.sourceId}-search`)) {
        this.map.removeSource(`${this.sourceId}-search`);
      }
    },
    onToggleShowLayer(layer) {
      if (Object.keys(this.layersMap).length < 2) return;
      let visibility = this.layersMap[layer.id].layout.visibility || "visible";
      let per_fix = this.isComplexSearch ? PRE_FIX_DATA_SEARCH : PRE_FIX;
      per_fix.map((x) => {
        this.map.setLayoutProperty(
          `${layer.id}${x}`,
          "visibility",
          visibility !== "visible" ? "visible" : "none"
        );
      });
      this.layersMap[layer.id].layout.visibility =
        visibility === "visible" ? "none" : "visible";
      this.$forceUpdate();
    },
    resize() {
      if (this.map) this.map.resize();
    },
    onSetIconOverlap(overlap) {
      if (!this.map || !this.layersMap) return;
      Object.keys(this.layersMap).forEach((layerId) => {
        let layer = this.map.getLayer(layerId);
        if (layer) {
          layer.setLayoutProperty("icon-allow-overlap", overlap);
        }
      });
    },
    checkPointInCurrentView(point) {
      if (!this.map) return;
      const bounds = this.map.getBounds();
      const boundsGeometry = polygon([
        [
          [bounds.getNorthWest().lng, bounds.getNorthWest().lat],
          [bounds.getNorthEast().lng, bounds.getNorthEast().lat],
          [bounds.getSouthEast().lng, bounds.getSouthEast().lat],
          [bounds.getSouthWest().lng, bounds.getSouthWest().lat],
          [bounds.getNorthWest().lng, bounds.getNorthWest().lat],
        ],
      ]);
      return booleanPointInPolygon(point, boundsGeometry);
    },
  },
};
</script>
<style></style>
